/* ------------------------ GLOBAL STYLES ------------------------ */

  /* Wizardry - Scalable Fonts */
   @media only screen and (min-width: 1920px) {
     body {font-size: 16px;}
   }
   @media only screen and (min-width: 991px) {
     .nav__link {font-size: 16px;}
   }

   /* Custom Cursor */
   /* Show on Desktop */
   #customCursor {
     display: flex;
   }

   /* Hide on Tablet > */
   @media(max-width:991px){
     #customCursor {
        display: none;
     }
   }

  /* Page transition container */
	.transition__component {
		visibility: hidden;
    display: block;
  }

  /* Force video mask to use GPU instead of CPU*/
  .sticky__circle__element {
  	-webkit-transform: translateZ(0);
  }

  /* Set image aspect ratios */
  /* Append these classes to any container that an image sits in, to set the ratio */
  .aspect1-1 {
    aspect-ratio: 1 / 1;
  }
  .aspect9-7 {
    aspect-ratio: 9 / 7;
  }
  .aspect16-9 {
    aspect-ratio: 16 / 9;
  }
  .aspect2-1 {
    aspect-ratio: 2 / 1;
  }

  /* Reference Splide JS for slider component https://splidejs.com/*/
  .splide__track {
    overflow: visible;
  }

  /* Get rid of Webflow bullshit */
  .w-nav-overlay {
  	display: none !important;
  }

  /* Disables 'bounce' behaviour when the end of the page is reached */
  body {
   overscroll-behavior-y: none;
  }

/* ------------------------ CUSTOM NAV COMPONENT ------------------------ */

#navFixed {
  margin-top: -72px;
}

.navFixedInitial {
  margin-top: -72px;
  opacity: 0%;
}

.navbar__mobile {
	display: none;
}

.w-nav-overlay {
	display: none !important;
}

.navFixedAnimOut {
  animation-name: navFixedOut;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  /* animation-delay: 2s; */
}

.navFixedAnimIn {
  animation-name: navFixedIn;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
  /* animation-delay: 2s; */
}

.navLogoAnimOut {
  animation-name: navLogoOut;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}

.navLogoAnimIn {
  animation-name: navLogoIn;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}

.navMobZTop {
  z-index: 9999;
}

.navMobZBottom {
  z-index: 99;
}

.hide {
  display: none;
}

.showFlex {
  display: flex;
}

.mobileNavAbsolute {
  position: absolute;
}

.mobileNavFixed {
  position: fixed;
}

.enableScroll {
  height: auto;
  overflow: visible;
  scroll-behavior: auto !important;
}

.disableScroll {
  height: 100%;
  overflow: hidden;
}

@keyframes navFixedOut {
  from {
    margin-top: 0px;
    opacity: 100%;
  }
  to {
    margin-top: -72px;
    opacity: 0%;
  }
}

@keyframes navFixedIn {
  from {
    margin-top: -72px;
    opacity: 0%;
  }
  to {
    margin-top: 0px;
    opacity: 100%;
  }
}

@keyframes navLogoIn {
  from {
    margin-right: -100px;
  }
  to {
    margin-right: 50px;
  }
}

@keyframes navLogoOut {
  from {
    margin-right: 50px;
  }
  to {
    margin-right: -100px;
  }
}
